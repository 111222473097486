import { createReactOidc } from 'oidc-spa/react/react';

// Injected by Webpack's DefinePlugin.
// eslint-disable-next-line no-undef
export const oidcClient = OIDC_CLIENT;
if (typeof oidcClient !== 'string') {
  throw new Error('Expected OIDC_CLIENT environment variable to be set.');
}

// Injected by Webpack's DefinePlugin.
// eslint-disable-next-line no-undef
export const oidcIssuerUrl = OIDC_ISSUER_URL;
if (typeof oidcIssuerUrl !== 'string') {
  throw new Error('Expected OIDC_ISSUER_URL environment variable to be set.');
}

export const { OidcProvider, useOidc, getOidc } = createReactOidc({
  issuerUri: oidcIssuerUrl,
  clientId: oidcClient,
  publicUrl: undefined,
});
